<template>
  <div class="size-full flex-nowrap rounded px-[1rem] py-[0.5rem] max-md:bg-[#F2F2F2]" :class="{ active: isActive }" @click="onClickTrigger">
    <a class=" text-[0.875rem]"><slot></slot></a>
  </div>
</template>

<script setup>
  import { listingSorting } from "@store/listingSortingStore.js";
  import { computed } from 'vue';
  import { useStore } from '@nanostores/vue';

  const props = defineProps({
    sortingKey: {
      type: String,
      required: true
    }
  });

  const onClickTrigger = () => {
    listingSorting.set(props.sortingKey);
  };

  const sortingStore = useStore(listingSorting);

  const isActive = computed(() => {
    return sortingStore.value === props.sortingKey;
  });

</script>

<style scoped>
  div.active {
    background-color: #DDDDDD;
    color: black;
    font-weight: 600;
  }

  @media screen and (max-width: 767px) {
    div.active {
      background-color: black;
      color: white;
    }
  }
</style>